@use "../global" as *;
/////////////////////////////////////////////////////////////////////////////////////////////////////////

////  ページ外アンカー位置調整（別ページのidに飛ぶとき。JSだけの調整で上手くいかない時）

/////////////////////////////////////////////////////////////////////////////////////////////////////////
.l-anchorFix {
  padding-top: $headerHeight;
  margin-top: -1 * $headerHeight;
  @include mq-max(content) {
    padding-top: $headerHeight-sp;
    margin-top: -1 * $headerHeight-sp;
  }
  //微調整
  &--10px {
    padding-top: $headerHeight + 10px;
    margin-top: -1 * $headerHeight - 10px;
    @include mq-max(content) {
      padding-top: $headerHeight-sp + 10px;
      margin-top: -1 * $headerHeight-sp - 10px;
    }
  }
}

//gridlex調整
[class*="grid"] {
  margin-bottom: -1 * $gridlexPadding; //gridlexを使うことでできてしまう下余白を相殺。
}
[class*="col-"] {
  img {
    width: 100%;
    display: block;
    line-height: 1;
  }
}
[class*="col-1"],
[class*="col-2"],
[class*="col-3"],
[class*="col-4"] {
  p {
    line-height: 1.7;
    @include mq-max(xs) {
      line-height: 1.8;
    }
  }
}