@use "sass:math";
@use "../../global" as *;

///////////////////////////////
// 基本パターン
///////////////////////////////
$flowBgColor: $bgColor;
$flowItemMargin: 75px;
$flowItemMargin_xs: 50px;
$flowItemWidth: 800px;

//アロー関連
$is_flowArrow: true; //アローがいる場合はtrue
$flowArrowColor: #E2E2E2;


// コンテナレイアウト
.c-flowLayout {
  counter-reset: flowIndex;
  &__item {
    max-width: $flowItemWidth;
    margin: 0 auto $flowItemMargin;
    @include mq-max(xs) {
      margin-bottom: $flowItemMargin_xs;
    }

    //アローあり
    @if $is_flowArrow {
      position: relative;
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 25px 0 25px;
        border-color: $flowArrowColor transparent transparent transparent;
        position: absolute;
        bottom: (25px + math.div(($flowItemMargin - 25px), 2)) * -1;
        left: 50%;
        transform: translateX(-50%);
        @include mq-max(xs) {
          border-width: 17px 17px 0 17px;
          bottom: (17px + math.div(($flowItemMargin_xs - 17px), 2)) * -1;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
      @if $is_flowArrow {
        &::after {
          content: none;
        }
      }
    }
  }
}


// 各フロー内容
.c-flowDefault {
  background-color: $flowBgColor;
  &__inner {
    padding: 25px 40px;
    @include mq-max(sm) {
      padding: 18px 20px;
    }
    @include mq-max(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__head {
    display: flex;
    align-items: stretch;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid $bdrColor;
    @include mq-max(xs) {
      padding-bottom: 18px;
      margin-bottom: 18px;
    }
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  &__index {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    padding: 14px 25px 14px 0;
    border-right: 1px solid $bdrColor;
    @include mq-max(xs) {
      padding-right: 15px;
    }
    &::before {
      content: counter(flowIndex, decimal-leading-zero);
      counter-increment: flowIndex;
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    padding: 5px 0 5px 25px;
    @include mq-max(xs) {
      padding-left: 15px;
    }

    &>span {}
  }

  // &__body {}


  //Mod
  &--black {
    background-color: #3C3C3C;
    color: $white;

    .c-flowDefault {
      &__head, &__index {
        border-color: $white;
      }
    }
  }

  //数字なし
  &--noIndex {
    .c-flowDefault {
      &__index {
        display: none;
        &::before {
          content: none;
        }
      }
      &__title {
        padding: 5px 0;
        justify-content: center;
      }
    }
  }
}