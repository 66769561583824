@use "../global" as *;


.c-list {
  list-style-type: none;
  position: relative;
  margin-bottom: 30px;
  line-height: 1.625;
  &:last-child {
    margin-bottom: 0;
  }

  &__item {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }


  //Mod
  //丸付き
  &--disc {
    &>.c-list {
      &__item {
        position: relative;
        padding-left: 1.5em;
        &::before {
          content: '・';
          display: inline-block;
          color: $keyColor;
          position: absolute;
          top: -0.2em;
          left: 0;
          font-size: 1.5em;
          line-height: 1.5;
        }
      }
    }
  }

  //四角付き
  &--rect {
    &>.c-list {
      &__item {
        position: relative;
        padding-left: 1.5em;
        &::before {
          content: '■';
          display: inline-block;
          color: $keyColor;
          position: absolute;
          top: .8em;
          left: .8em;
          font-size: .5em;
          line-height: 1.5;
        }
      }
    }
  }

  //カウント
  &--count {
    counter-reset: count;
    &>.c-list {
      &__item {
        position: relative;
        padding-left: 1.75em;
        &::before{
          counter-increment: count;
          content:counter(count)".";
          position: absolute;
          left: .5em;
          color: $keyColor;
        }
        &:nth-child(n + 10) {
          &::before {
            left: -0.05em;
          }
        }
        &:nth-child(n + 100) {
          &::before {
            left: -0.5em;
          }
        }
      }
    }
  }

  //カッコつきカウント
  &--brackets{
    counter-reset: count;
    &>.c-list {
      &__item {
        position: relative;
        padding-left: 1.75em;
        &:before{
          counter-increment: count;
          content:"("counter(count)")";
          position: absolute;
          left: .2em;
          color: $keyColor;
        }
        &:nth-child(n + 10) {
          &::before {
            left: -0.35em;
          }
        }
        &:nth-child(n + 100) {
          &::before {
            left: -0.9em;
          }
        }
      }
    }
  }

  //注釈
  &--comment {
    line-height: 1.5;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }

    &>.c-list {
      &__item {
        position: relative;
        font-size: 14px;
        padding-left: 1.5em;
        &::before{
          content: '※';
          display: inline-block;
          position: absolute;
          left: .35em;
        }
      }
    }
  }

  //注釈ナンバー
  &--commentNum {
    counter-reset: count;
    line-height: 1.5;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }

    &>.c-list {
      &__item {
        position: relative;
        font-size: 14px;
        padding-left: 2.1em;
        &::before {
          counter-increment: count;
          content: '※'counter(count);
          position: absolute;
          left: 0.3em;
        }
        &:nth-child(n + 10) {
          padding-left: 2.7em;
        }
        &:nth-child(n + 100) {
          padding-left: 3.3em;
        }
      }
    }
  }
}