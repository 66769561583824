@use "../global" as *;

.c-box {
  width: 100%;
  background: $white;
  border: 1px solid $bdrColor;
  margin-bottom: 15px;
  container-type: inline-size;
  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    padding: 40px;
    @include mq-container(sm) {
      padding: 30px;
    }
    @include mq-container(xs) {
      padding: 20px;
    }
  }
  &__heading {
    font-size: 18px;
    margin-bottom: 15px;
    @include mq-max(sm) {
      margin-bottom: 10px;
    }
  }


  //Mod
  &--bgGray {
    background-color: $gray-l;
    border-color: $gray-l;
  }
}


//ボックススライダー（swiper併用）
.c-boxSlider {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  @include mq-max(xs) {
    max-width: calc(100% - 30px);
  }

  &__container {}
  &__list {}
  &__item {
    width: 520px !important;
    @include mq-max(sm) {
      width: 360px !important;
    }
    @include mq-max(xs) {
      width: calc(100% - 45px) !important;
    }
  }
  &__content {
    padding: 0 1px;
  }

  // swiper調整
  //ナビボタン
  .swiper-button-prev, .swiper-button-next {
    width: 50px;
    height: 50px;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    transition: all $transition;
    &::after {
      font-size: 20px;
      line-height: 1;
      color: $keyColor;
    }
    @media (any-hover: hover) {
      &:hover {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
      }
    }
  }
  .swiper-button-prev {
    left: -35px;
    @include mq-max(bp900) {
      left: -15px;
    }
    @include mq-max(sm) {
      left: -5px;
    }
    @include mq-max(xs) {
      left: -15px;
    }
    &::after {
      @include fa('f104');
    }
  }
  .swiper-button-next {
    right: -35px;
    @include mq-max(bp900) {
      right: -15px;
    }
    @include mq-max(sm) {
      right: -5px;
    }
    @include mq-max(xs) {
      right: -15px;
    }
    &::after {
      @include fa('f105');
    }
  }

  //Mod
  &--sideGradient {
    &::before, &::after {
      content: '';
      width: 75px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 2;
      @include mq-max(xs) {
        content: none;
      }
    }
    &::before {
      left: -1px;
      background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }
    &::after {
      right: -1px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }
  }
}