@use "../global" as *;
// 便利CSS集
//使用したい場合はそのページのページSCSSに直接読み込んでください。

/////////////////////////////////////////////////////////////////////////
//余白調整
///////////////////////////////////////////////////////////////////////////

//使用例
//.u-pb20 → padding-bottom:20px;
//.u-xs-pb60 → 横幅xs以下でpadding-bottom:60px;

.u {
  //5の倍数で作成
  @for $i from 0 through 20 {
    &-pt#{5 * $i} {
      padding-top: #{$i*5}px !important;
    }
    &-pb#{5 * $i} {
      padding-bottom: #{$i*5}px !important;
    }
    &-mt#{5 * $i} {
      margin-top: #{$i*5}px !important;
    }
    &-mb#{5 * $i} {
      margin-bottom: #{$i*5}px !important;
    }
  }
}

// md以下適用
.u-md {
  //5の倍数で作成
  @for $i from 0 through 20 {
    &-pt#{5 * $i} {
      @include mq-max(md) {
        padding-top: #{$i*5}px !important;
      }
    }
    &-pb#{5 * $i} {
      @include mq-max(md) {
        padding-bottom: #{$i*5}px !important;
      }
    }
    &-mt#{5 * $i} {
      @include mq-max(md) {
        margin-top: #{$i*5}px !important;
      }
    }
    &-mb#{5 * $i} {
      @include mq-max(md) {
        margin-bottom: #{$i*5}px !important;
      }
    }
  }
}

// sm以下適用
.u-sm {
  //5の倍数で作成
  @for $i from 0 through 20 {
    &-pt#{5 * $i} {
      @include mq-max(sm) {
        padding-top: #{$i*5}px !important;
      }
    }
    &-pb#{5 * $i} {
      @include mq-max(sm) {
        padding-bottom: #{$i*5}px !important;
      }
    }
    &-mt#{5 * $i} {
      @include mq-max(sm) {
        margin-top: #{$i*5}px !important;
      }
    }
    &-mb#{5 * $i} {
      @include mq-max(sm) {
        margin-bottom: #{$i*5}px !important;
      }
    }
  }
}

// xs以下適用
.u-xs {
  //5の倍数で作成
  @for $i from 0 through 20 {
    &-pt#{5 * $i} {
      @include mq-max(xs) {
        padding-top: #{$i*5}px !important;
      }
    }
    &-pb#{5 * $i} {
      @include mq-max(xs) {
        padding-bottom: #{$i*5}px !important;
      }
    }
    &-mt#{5 * $i} {
      @include mq-max(xs) {
        margin-top: #{$i*5}px !important;
      }
    }
    &-mb#{5 * $i} {
      @include mq-max(xs) {
        margin-bottom: #{$i*5}px !important;
      }
    }
  }
}


//背景色
.u-bg {
  &--white {
    background-color: $white;
  }
}