@use "../global" as *;
///////////////////////////////////////////////////////////////////////////////////////////////////////

//お知らせ一覧

///////////////////////////////////////////////////////////////////////////////////////////////////////

//基本形
.c-newslist {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }

  &__item {
    border-bottom: 1px solid $bdrColor;
    &:first-child {
      border-top: 1px solid $bdrColor;
    }
    &>a {
      display: block;
      transition: all $transition;
      padding: 16px 40px 16px 20px;
      position: relative;
      @include mq-max(sm) {
        padding: 13px 30px 13px 15px;
      }
      &:after {
        @include fa('f105');
        color: $textColor;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        transition: all $transition;
        @include mq-max(sm) {
          right: 10px;
        }
      }

      &[target="_blank"] {
        &:after {
          @include fa('f35d');
          -webkit-font-smoothing:antialiased;
          font-size: 14px;
        }
      }
      &[href$=".pdf"] {
        &:after {
          @include fa('f1c1');
          font-size: 16px;
          font-weight: 400;
          color: $red;
          -webkit-font-smoothing:antialiased;
        }
      }

      //ホバー時
      &:hover {
        dd {
          color: $keyColor;
        }
        &:after {
          color: $keyColor;
        }
        .c-newslist {
          &__date {
            color: $keyColor;
          }
          &__cat {
            background-color: $keyColor;
          }
        }
      }
    }

    dl {
      display: flex;
      align-items: flex-start;
      @include mq-max(sm) {
        flex-direction: column;
      }
    }
    dt {
      display: flex;
      // align-items: center;
      margin-right: 13px;
      flex-shrink: 0;
      @include mq-max(sm) {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
    dd {
      flex-grow: 1;
      font-size: 16px;
      font-weight: 400;
      color: $textColor;
      transition: all $transition;
      @include mq-max(sm) {
        font-size: 15px;
      }
    }
  }

  &__date {
    font-size: 13px;
    font-weight: 500;
    margin-right: 18px;
    color: $textColor;
    transition: all $transition;
    line-height: 2;
    &:last-child {
      margin-right: 0;
    }
    @include mq-max(sm) {
      font-size: 13px;
      margin-right: 10px;
    }
  }
  &__cat {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .04em;
    color: $white;
    background-color: #5C5C5C;
    display: block;
    padding: 3px 5px 0;
    min-width: 118px;
    text-align: center;
    transition: all $transition;
    @include mq-max(sm) {
      font-size: 13px;
      min-width: 67px;
    }

    //英語サイト
    .is-lang--eng & {
      letter-spacing: normal;
    }
  }
}


//2カラム
.c-newslist-2col {
  display: flex;
  @include mq-max(iPadPro-v) {
    flex-direction: column;
  }
  &__head {
    flex: 0 0 235px;
    background-color: $gray-l;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq-max(md) {
      flex-basis: 200px;
    }
    @include mq-max(iPadPro-v) {
      flex: 1 1 100%;
    }
    &-inner {
      text-align: center;
      padding: 45px 10px;
      @include mq-max(iPadPro-v) {
        width: 100%;
        padding: 33px 15px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__heading {
    font-size: 21px;
    font-weight: 600;
    margin: 0 0 20px;
    text-align: left;
    @include mq-max(iPadPro-v) {
      margin-bottom: 0;
      font-size: 18px;
    }
  }

  &__body {
    flex: 1 1 100%;
    background-color: $gray-l;
    padding: 7px 50px;
    display: flex;
    align-items: center;
    @include mq-max(content) {
      padding: 0 30px;
    }
    @include mq-max(md) {
      padding: 0 15px;
    }
    @include mq-max(iPadPro-v) {
      padding: 0 0 5px;
    }
  }

  //パーツ調整
  .c-newslist {
    width: 100%;
    &__item {
      &:first-child {
        border-top-color: transparent;
      }
      &:last-child {
        border-bottom-color: transparent;
      }

      &>a {
        padding-top: 13px;
        padding-bottom: 13px;
        &::after {
          @include mq-max(sm) {
            right: 15px;
          }
        }
      }
    }
  }
}