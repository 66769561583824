@use "../global" as *;


//【使い方】.gridを.l-Gridで括る。
.l-Grid {
  overflow: hidden;
  &--18 {
    & > [class*="grid"] {
      @include gridTune(18px, none);
    }
  }
  &--42 {
    & > [class*="grid"] {
      @include gridTune(42px, none);
    }
  }
  &--42-mdDefault {
    & > [class*="grid"] {
      @include gridTune(42px, md);
    }
  }
}


//max-width(できるだけl-sectionやl-section__innerと同じ場所では使わない。内部で使う)
.l-mw {
  margin: 0 auto;
  width: 100%;
  &--contentWidth {
    max-width: $contentWidth;
  }
  &--800 {
    max-width: 800px;
  }
}


//background共通設定
@mixin bg_scss() {
  @include mq-max(content) {
    padding-top: $sectionPadding;
    margin: 0 0 $sectionPadding;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//背景色（.l-sectionを囲って使う）
.l-bg {
  @include bg_scss();
  &--keyColor {
    background-color: $keyColor;
  }
}