@use "sass:math";
@use "mediaquery" as *;
@use "variables" as *;

//grid調整用mixin
@mixin gridTune($padding, $breakpoint) {
  margin-bottom: -1 * $padding;
  margin-left: -1 * math.div($padding,2);
  margin-right: -1 * math.div($padding,2);
  @include mq-max($breakpoint) {
    margin: 0 -1*math.div($gridlexPadding,2) -1*$gridlexPadding; // 調整前に戻る。
  }
  &>[class*="col-"] {
    padding: 0 math.div($padding,2) $padding;
    @include mq-max($breakpoint) {
      padding: 0 math.div($gridlexPadding,2) $gridlexPadding; // 調整前に戻る。
    }
  }
}


// 手順メモ
// 1.「util」に入れて、all.scssに読み込んである。
// ※もし[class*="grid"] のmarginが優先度的にきいていない場合は、この記述だけ他の場所に移動する（layout.scssなど）。

// 2.gridクラスに@includeで適用。（可能なら「.grid」に直接適用するのではなく、別にクラスを設ける方がいい）

// 3.$paddingにお望みの$gridlexPaddingを入力

// 例：
// .samaple {
//   @include gridTune(60px, sm);
// }

// ↓↓↓

// gridコンテンツ幅が60pxになりブレイクポイントsmで元に戻る