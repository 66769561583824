@use "../global" as *;
//スライダーの設定を読み込み(不要なら記述削除)]
@use "cvSlider" as *;
.c-cvArea {
  &__title {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
    @include mq-max(xs) {
      font-size: 23px;
      margin-bottom: 30px;
    }
    &--white {
      color: $white;
    }
  }

  &__text {
    font-weight: 600;
    margin-bottom: 30px;

    //文字色白の場合
    &--white {
      color: $white;
    }
  }

  &__inner {
    padding: 60px 20px 100px;
    background: $gray;
    //背景画像を設定する場合webpを記載
    // background: url('');
    position: relative;

    .is-IE & {
      //背景画像を設定する場合jpgを記載
      // background: url('');
    }
    &__textArea {
      max-width: $contentWidth;
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }

    //背景画像にフィルターを付ける設定
    &--filter {
      &::after {
        content: '';
        opacity: .7;
        background-color: #051224;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }
  }

  //ボタン設定
  &__buttonArea {
    position: relative;
    z-index: 1;
    margin: 0 auto;
  }
  &__button {
    width: 100%;
    height: 100%;
    padding: 28px 45px 28px 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}