@use "sass:math";
@use "../../global" as *;

/////////////////////////////////////////////////////
//画像＋文章　ズレ
/////////////////////////////////////////////////////
$alternateGapHorizontal: 8%; //「%」、「px」
$alternateGapVertical: 50px; //「px」
$alternateGapImageWidth: 50%; //「%」、「px」
$alternateGapBgColor: $bgColor;
$alternateGapChangePoint: iPadPro-v;

.l-alternateGap {
  &__inner {
    display: flex;
    align-items: stretch;
    @include mq-max($alternateGapChangePoint) {
      flex-direction: column;
    }
  }
  &__img {
    position: relative;
    z-index: 2;
    width: $alternateGapImageWidth;
    flex-shrink: 0;
    img {
      @extend %imgFormat;
    }
    @include mq-max($alternateGapChangePoint) {
      width: 100%;
    }
  }
  &__body {
    position: relative;
    top: $alternateGapVertical;
    z-index: 1;
    width: calc((100% - $alternateGapImageWidth) + $alternateGapHorizontal);
    min-height: 100%;
    flex-shrink: 0;
    background-color: $alternateGapBgColor;
    margin-left: -1 * $alternateGapHorizontal;
    padding: 60px 50px 60px calc(40px + $alternateGapHorizontal);
    @include mq-max(content) {
      padding: 30px 20px 30px calc(20px + $alternateGapHorizontal);
    }
    @include mq-max($alternateGapChangePoint) {
      top: 0;
      width: 100%;
      min-height: auto;
      padding: 30px;
      margin-left: 0;
    }
    @include mq-max(sm) {
      padding-right: 20px;
      padding-left: 20px;
    }
    @include mq-max(xs) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }


  //Mod
  //反転
  &--reverse {
    .l-alternateGap {
      &__inner {
        flex-direction: row-reverse;
        @include mq-max($alternateGapChangePoint) {
          flex-direction: column;
        }
      }
      &__body {
        margin-right: -1 * $alternateGapHorizontal;
        padding: 60px calc(40px + $alternateGapHorizontal) 60px 50px;
        @include mq-max(content) {
          padding: 30px calc(20px + $alternateGapHorizontal) 30px 20px;
        }
        @include mq-max($alternateGapChangePoint) {
          width: 100%;
          padding: 30px;
          margin-right: 0;
        }
        @include mq-max(sm) {
          padding-right: 20px;
          padding-left: 20px;
        }
        @include mq-max(xs) {
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }


  //レイアウト
  // &__wrap {}
  &__block {
    margin-bottom: 90px + $alternateGapVertical;
    @include mq-max($alternateGapChangePoint) {
      margin-bottom: 60px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}