@use "sass:math";
@use "../global" as *;

///////////////////////////////
// 基本パターン
///////////////////////////////
$hukidashiContainerWidth: 800px; //コンテナの横幅
$hukidashiWidth: 525px; //吹き出しの横幅
$hukidashiBdrColor: $bdrColor;
$hukidashiTriangleWidth: 16px;
$hukidashiTriangleHeight: 17px;

// コンテナレイアウト
.c-hukidashiLayout {
  max-width: $hukidashiContainerWidth;
  width: 100%;
  margin: 0 auto;

  &__item {
    margin-bottom: $hukidashiTriangleHeight + 10px;
    max-width: $hukidashiWidth;
    width: 100%;
    @include mq-max(sm) {
      max-width: 80%;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(even) {
      margin-left: auto;

      .c-hukidashi {
        &__inner {
          &::before, &::after {
            left: auto;
            right: 21px;
          }
        }
      }
    }
  }
}


// 吹き出し　単体
.c-hukidashi {
  width: 100%;

  &__inner {
    position: relative;
    padding: 26px 30px;
    background-color: $white;
    filter: drop-shadow(0 0 1px $hukidashiBdrColor);
    @include mq-max(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include mq-max(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: $hukidashiTriangleHeight math.div($hukidashiTriangleWidth, 2) 0 math.div($hukidashiTriangleWidth, 2);
      border-color: $white transparent transparent transparent;
      position: absolute;
      bottom: -1 * $hukidashiTriangleHeight;
      left: 21px;
    }
  }


  //Mod
  //線くっきりパターン
  &--solid {
    border: 1px solid $hukidashiBdrColor;

    .c-hukidashi {
      &__inner {
        filter: none;
        &::before, &::after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: $hukidashiTriangleHeight math.div($hukidashiTriangleWidth, 2) 0 math.div($hukidashiTriangleWidth, 2);
          position: absolute;
          left: 21px;
        }
        &::before {
          border-color: $white transparent transparent transparent;
          bottom: -1 * $hukidashiTriangleHeight + 1px;
          z-index: 1;
        }
        &::after {
          border-color: $hukidashiBdrColor transparent transparent transparent;
          bottom: -1 * $hukidashiTriangleHeight - 1px;
          z-index: 0;
        }
      }
    }
  }

  //背景色
  &--bgGray {
    .c-hukidashi {
      &__inner {
        background-color: $gray;
        &::before {
          border-color: $gray transparent transparent transparent;
        }
      }
    }
  }
}