@use "sass:math";
@use "../../global" as *;

/////////////////////////////////////////////////////
//互い違い
/////////////////////////////////////////////////////
$alternateImageWidth: 50%; //「%」、「px」
$alternateContentPadding: 50px; //画像と文章の幅
$alternateBgColor: $bgColor;
$alternateChangePoint: iPadPro-v;

//基本パターン
.l-alternate {
  &__inner {
    display: flex;
    align-items: center;
    margin: 0 -1 * math.div($alternateContentPadding, 2);
    @include mq-max(content) {
      margin: 0 -1 * math.div($contentPadding,2);
    }
    @include mq-max($alternateChangePoint) {
      flex-direction: column;
    }
  }
  &__column {
    position: relative;
    width: 100%;
    padding: 0 math.div($alternateContentPadding, 2);
    @include mq-max(content) {
      padding: 0 math.div($contentPadding,2);
    }

    //Mod
    &--img {
      position: relative;
      width: $alternateImageWidth;
      flex-shrink: 0;
      @include mq-max($alternateChangePoint) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    &--desc {
      flex-grow: 1;
    }
  }
  &__img {
    img {
      @extend %imgFormat;
    }
    @include mq-max($alternateChangePoint) {
      width: 100%;
    }
  }
  // &__body {}


  //Mod
  //反転
  &--reverse {
    .l-alternate {
      &__inner {
        flex-direction: row-reverse;
        @include mq-max($alternateChangePoint) {
          flex-direction: column;
        }
      }
    }
  }
  &--itemsStart {
    .l-alternate {
      &__inner {
        align-items: flex-start;
      }
    }
  }


  //レイアウト
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  &__block {
    max-width: $contentWidth;
    width: 100%;
    margin-bottom: 90px;
    @include addSectionSidePadding;
    @include mq-max($alternateChangePoint) {
      margin-bottom: 60px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}


//以下拡張クラス（.l-alternateと併用）
// 画像側に装飾パターン
$alterBgShiftPadding: 50px;
.l-alterBgShift {
  &__column {
    &--img {
      position: relative;
      padding-top: $alterBgShiftPadding;
      padding-bottom: $alterBgShiftPadding;
      @include mq-max($alternateChangePoint) {
        padding-top: math.div($alterBgShiftPadding, 2);
        padding-bottom: math.div($alterBgShiftPadding, 2);
      }
      &::before {
        content: '';
        display: block;
        background-color: $alternateBgColor;
        width: calc((100% - $alternateContentPadding) / 3);
        height: 100%;
        position: absolute;
        top: 0;
        left: math.div($alternateContentPadding, 2);
        z-index: -1;
        @include mq-max(content) {
          left: 0;
        }
      }
      &::after {
        content: '';
        display: block;
        background-color: $alternateBgColor;
        width: calc((100vw - $contentWidth) / 2);
        height: 100%;
        position: absolute;
        top: 0;
        left: calc((-1 * (100vw - $contentWidth) / 2) + (math.div($alternateContentPadding, 2)));
        z-index: -1;
        pointer-events: none;
        @include mq-max(content) {
          width: $contentPadding;
          left: -1 * $contentPadding;
        }
      }
    }
  }

  //反転(.l-alternateのModを調整)
  &.l-alternate--reverse {
    .l-alterBgShift {
      &__column {
        &--img {
          &::before {
            left: auto;
            right: math.div($alternateContentPadding, 2);
            @include mq-max(content) {
              right: 0;
            }
          }
          &::after {
            left: auto;
            right: calc((-1 * (100vw - $contentWidth) / 2) + (math.div($alternateContentPadding, 2)));
            @include mq-max(content) {
              right: -1 * $contentPadding;
            }
          }
        }
      }
    }
  }
  &.l-alternate--itemsStart {
    .l-alternate {
      &__column {
        &--desc {
          padding-top: $alterBgShiftPadding;
          @include mq-max($alternateChangePoint) {
            padding-top: 0;
          }
        }
      }
    }
  }
}


// 図形だけ互い違い
$alterLabelFontSize: 28px; //案件ごとに使う見出しサイズが違うため、ここの数値は使用する見出しのフォントサイズに変更する。【注意】$alternateChangePointで指定しているブレイクポイントまではなるべくフォントサイズを変更しないように。
$alterLabelGap: 40px; //上部ズレ具合
.l-alterLabel {
  position: relative;
  padding-bottom: 60px;
  @include mq-max($alternateChangePoint) {
    padding-bottom: 40px;
  }
  &::before {
    content: '';
    display: block;
    width: calc((100vw - $contentWidth) / 2 + ($contentWidth - 140px));
    height: calc(100% - ($alterLabelGap + ($alterLabelFontSize * $lineHeight_heading / 2))); //上記の変数で対応できない場合はここを調整。
    background-color: $alternateBgColor;
    position: absolute;
    bottom: 0;
    left: calc(-1 * (100vw - $contentWidth) / 2);
    z-index: -1;
    @include mq-max(content) {
      left: -1 * $contentPadding * 1.5;
    }
    @include mq-max(md) {
      width: calc(100% - 80px);
      left: -1 * $contentPadding;
    }
    @include mq-max($alternateChangePoint) {
      width: calc(100% - 60px);
    }
  }

  &__inner {
    align-items: flex-start;
  }
  &__body {
    padding-top: $alterLabelGap;
    @include mq-max($alternateChangePoint) {
      padding-top: 0;
    }
  }


  //反転
  &--reverse {
    &::before {
      left: auto;
      right: calc(-1 * (100vw - $contentWidth) / 2);
      @include mq-max(content) {
        right: -1 * $contentPadding * 1.5;
      }
      @include mq-max(md) {
        right: -1 * $contentPadding;
      }
    }
  }
}