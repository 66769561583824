@use "variables" as *;
////CSS汎用コード集

/////////////////////////////////////////////////////////////////////

// 使い方

//%sample {~~~}
//
//.sample {
//  @extend %sample;
//  上書きも可能
//}

/////////////////////////////////////////////////////////////////////

//上下中央揃え（親に「position:relative;」が必要）
%v_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

%imgFormat {
  display: block;
  width: 100%;
  line-height: 0;
}