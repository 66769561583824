@use "../global" as *;
.l-f {
  background-color:#000000;
  color: $white;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
    flex-direction: column;
    @include mq-max(content) {
      padding: 30px 20px;
    }
    @include mq-max(xs) {
      flex-wrap: wrap;
      margin-bottom: -30px;
    }
  }
  &__nav {
    padding: 50px 0 30px 0;
    text-align: center;
    background-color: $white;
    .l-f__links {
      text-align: center;
    }
    .l-f__links__item {
      display: inline;
      padding: 0 10px;
      border-right: solid 1px #000000;
      a {
        padding: 2px 0;
        color: #000000;
      }
    }
  }
  &__info {
    display: block;
  }
  &__logo {
    > img {
      width: 280px !important;
      margin: 10px 0 15px 0;
    }
  }
  &__contact {
    color: $white;
    border: solid 2px $white;
    border-radius: 5px;
    padding:  2px 10px;
  }
  &__links {
      margin-left: 50px;
      @include mq-max(xs) {
        margin-left: 0;
        margin-bottom: 30px;
      }
      &:first-child {
        margin-left: 0;
      }
      @include mq-max(xs) {
        width: 50%;
      }
    }

  //Copyright
  &__copyright {
    text-align: center;
    display: block;
    color: $white;
    background-color: $black;
    padding: 10px 0;
  }
}
