@use "../global" as *;

.c-topicspath{
  max-width: $contentWidth;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0px;
  text-align: left;
  @extend %v_center;
  top: 75%;
  color: rgb($white,0.6);
  @include mq-max(sm){
    text-align: left;
  }
  li{
    display: inline-block;
    font-size: 16px;
    @include mq-max(sm){
      font-size: 0.7em;
    }
    &::after {
      @include fa('f105');
      display: inline-block;
      margin-left: 8px;
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
    a {
      color: $white;
    }
  }
}
