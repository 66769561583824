@use "../global" as *;
//IEサポート終了のお知らせ

.c-ieAttention {
  display: none;
  .is-IE & {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    background-color: $white;
    padding: 30px 0;
    box-shadow: $box-shadow;
  }

  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
  }
  &__txt {
    line-height: 1.5;
  }
  &__list {
    display: flex;
    margin: 0 -20px;
    &-item {
      padding: 0 20px;
    }
  }
  &__btn {
    display: inline-block;
    background-color: $keyColor;
    color: $white;
    padding: 10px 20px;
    &>i {
      margin-right: 5px;
    }
    &-wrap {
      text-align: right;
    }
  }
}