@use "../global" as *;
.pageTop {
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 99;
  opacity: 1;
  transition: none !important;
  @include mq-max(sm) {
    bottom: 20px;
    right: 10px;
  }
  &::after {
    @include fa('f077');
    display: inline-block;
    color: #fff;
    padding: 20px;
    background-color: $keyColor;
    border-radius: 50%;
    line-height: 1;
  }

  &:hover {
    opacity: .7;
  }
}
