/*
ユーティリティ系おまとめファイル
*/

//ホバー
@forward "hover";

//スポット
@forward "spot";

//テキスト
@forward "txt";

//便利クラス
@forward "utility";

