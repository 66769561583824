@use "../global" as *;

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {
  //////////////////////////////////////////////////////////////////////////////////
  //メイン見出し（マクロ化済）
  //////////////////////////////////////////////////////////////////////////////////
  &-main {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    // &__sub {}
  }



//  //////////////////////////////////////////////////////////////////////////////////
//  //中見出し
//  //////////////////////////////////////////////////////////////////////////////////
  &-middle {}



//  //////////////////////////////////////////////////////////////////////////////////
//  //小見出し
//  //////////////////////////////////////////////////////////////////////////////////
  &-small {}



//  //////////////////////////////////////////////////////////////////////////////////
//  //全体Modifier
//  //////////////////////////////////////////////////////////////////////////////////
  //真ん中寄せ
  &--center {
    text-align: center;
  }

  //白文字
  &--white {
    color: $white;
  }

}